<div class="ui inverted menu" style="border-radius: 0">
  <a class="item" routerLink="/home" routerLinkActive="active">
    Home
  </a>
  @if (userRoles.includes(clientRoles.ACCESS_CATALOG)) {
    <a class="item" routerLink="/topics" routerLinkActive="active">
      Kategorien
    </a>
    <a class="item" routerLink="/articles" routerLinkActive="active">
      Artikel
    </a>
  }
  @if (userRoles.includes(clientRoles.ACCESS_PUBLISH)) {
    <div class="ui pointing dropdown link item" suiDropdown>
      <span class="text">Publizieren</span>
      <i class="dropdown icon"></i>
      <div class="menu" suiDropdownMenu>
        <a class="item" routerLink="/author/articles">Artikel</a>
        <a class="item" routerLink="/author/internalMessages">Interne Mitteilungen</a>
      </div>
    </div>
  }
  <div class="ui inverted right menu">
    @if (userRoles.includes(clientRoles.ACCESS_SUBADMIN)) {
      <div class="ui pointing dropdown link item" suiDropdown>
        <span class="text">Administration</span>
        <i class="dropdown icon"></i>
        <div class="menu" suiDropdownMenu>
          <div class="item" routerLink="/subadmin/institution">Institution verwalten</div>
          <div class="item" routerLink="/subadmin/internalMessages">Interne Mitteilungen verwalten</div>
        </div>
      </div>
      <a class="item" routerLink="/statistics/subadmin/users" [queryParams]="{sortBy: 'newUsers'}" routerLinkActive="active">
        Eigene Statistiken
      </a>
    }
    @if (userRoles.includes(clientRoles.ACCESS_ADMIN)) {
      <a class="item" routerLink="/statistics/admin" [queryParams]="{sortBy: 'newArticles'}" routerLinkActive="active">
        Admin-Statistiken
      </a>
      <div class="ui pointing dropdown link item" suiDropdown>
        <span class="text">Adminbereich</span>
        <i class="dropdown icon"></i>
        <div class="menu" suiDropdownMenu>
          <a class="item" routerLink="/admin/topics">Kategorien</a>
          <a class="item" routerLink="/admin/articles">Artikel</a>
          <a class="item" routerLink="/admin/subjectareas">Fachrichtungen</a>
          <a class="item" routerLink="/admin/institutions">Institutionen</a>
          <a class="item" routerLink="/admin/users">Nutzer</a>
          <a class="item" routerLink="/admin/contacts">Kontaktpersonen</a>
          <a class="item" routerLink="/admin/mailing">Admin-Mail</a>
        </div>
      </div>
    }
    @if (userRoles.includes(clientRoles.ACCESS_SETTINGS)) {
      <a class="item" [routerLink]="['/settings/profile']" routerLinkActive="active">Einstellungen</a>
    }
    <a class="item" [routerLink]="['/contact']" routerLinkActive="active">Kontakt</a>
    <div class="item">
      @if (userRoles.length) {
        <button id="logout-button" class="ui primary button" (click)="keycloakService.logout()">Logout</button>
      } @else {
        <button id="login-button" class="ui primary button" (click)="keycloakService.login()">Login</button>
      }
    </div>
  </div>
</div>
